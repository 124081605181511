<template>
  <div class="app-container">
    <CrudTable
      entity="Customer"
      :columns="columns"
      :permission-code="'Customer'"
      :allow-create="false"
      :generate-summary="generateSummary"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { toDateStringVisual } from '@/utils/date';
import { booleanColumnFilter, booleanColumnFormatter } from '../../../../utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'firstName',
        'lastName',
        'company',
        {
          field: 'dateOfBirth',
          label: 'common.dateOfBirth',
          formatter: this.formatDate,
          filter: { type: 'date' }
        },
        {
          field: 'phone'
        },
        {
          field: 'email'
        },
        {
          field: 'hasAccountYN',
          label: 'customer.hasAccount',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this),
          width: 120
        },
        {
          field: 'blackListYN',
          label: 'customer.blackList',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this),
          width: 120
        }
      ]
    };
  },
  methods: {
    generateSummary({ firstName, lastName, email }) {
      return `${firstName} ${lastName} ${email}`;
    },
    formatDate(row, column, cellValue) {
      return toDateStringVisual(cellValue);
    }
  }
};
</script>

<style scoped></style>
